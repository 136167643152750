import React from 'react';
import MainLayout from 'components/layouts/MainLayout'
import ssa_c from 'images/ssa_c.png';
import stallone_yip from 'images/stallone_yip.png';
import karamjit_kaur from 'images/karamjit_kaur.png';
import vamshi_gankidi from 'images/vamshi_gankidi_c.png';
import zig_rubel from 'images/zig_rubel.png';
import MainPageTitle from 'components/titles/MainPageTitle';
import "components/sections/TeamSectionStyles.scss"
import { StoreContext } from 'store';
import TeamSectionMobile from 'components/sections/TeamSectionMobile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from "react-helmet"



function Team() {

    const store = React.useContext(StoreContext)

    return (
        <MainLayout>
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <MainPageTitle>Team</MainPageTitle>
            {store.isMobile ? <TeamSectionMobile />
                : <div className='team-page'>
                    <div className="content-container-1">
                        <div className="avatar-card txt-size-s">

                            <div className="main-avatar-body">
                                <img alt="JA" className="avatar-img" src={ssa_c} style={{ height: "35vh" }}></img>
                                <br />

                                <div className="avatar-title">
                                    <span className="avatar-title-name" ><b>Jerry Aujla</b></span>
                                    <a href="mailto:jerry.aujla@forsightdigital.com" className="title-icon-container mail-container">
                                        <FontAwesomeIcon icon={['fa', 'envelope']} className="title-icon" size="xs" />
                                    </a>
                                    <a href="/story/teams" target="_blank" className="title-icon-container linkedin-container">
                                        <FontAwesomeIcon icon={["fab", "linkedin-in"]} className="title-icon" size="xs" />
                                    </a>
                                </div>
                            </div>
                            <br />
                            <span className="txt-color-grey">Co-founder, Chief Technologist</span>
                        </div>
                        <div className="avatar-card txt-size-s">
                            <div className="main-avatar-body">
                                <img alt="VG" className="avatar-img" src={vamshi_gankidi}></img>
                                <br />

                                <div className="avatar-title">
                                    <span className="avatar-title-name" ><b>Vamshi Gankidi</b></span>
                                    <a href="mailto:vamshidher.gankidi@forsightdigital.com" className="title-icon-container mail-container">
                                        <FontAwesomeIcon icon={['fa', 'envelope']} className="title-icon" size="xs" />
                                    </a>
                                    <a href="https://www.linkedin.com/in/vamshidher-reddy-gankidi-93424347/" target="_blank" className="title-icon-container linkedin-container">
                                        <FontAwesomeIcon icon={["fab", "linkedin-in"]} className="title-icon" size="xs" />
                                    </a>
                                </div>
                            </div>
                            <br />
                            <span className="txt-color-grey">MBBS, MPH</span>
                            <br />
                            <span className="txt-color-grey">Co-founder, Chief Clinician</span>
                        </div>
                        <div className="avatar-card txt-size-s">
                        <div className="main-avatar-body">
                            <img alt="ZR" className="avatar-img" src={zig_rubel}></img>
                                <br />

                                <div className="avatar-title">
                                    <span className="avatar-title-name" ><b>Zig Rubel</b></span>
                                    <a href="mailto:zig.rubel@forsightdigital.com" className="title-icon-container mail-container">
                                        <FontAwesomeIcon icon={['fa', 'envelope']} className="title-icon" size="xs" />
                                    </a>
                                    <a href="https://www.linkedin.com/in/zigmund-rubel-faia-a80458/" target="_blank"className="title-icon-container linkedin-container">
                                        <FontAwesomeIcon icon={["fab", "linkedin-in"]} className="title-icon" size="xs" />
                                    </a>
                                </div>
                                
                            </div>
                            
                            <br />
                            <span className="txt-color-grey">FAIA, NCARB</span>
                            <br />
                            <span className="txt-color-grey">Co-founder, Chief Executive</span>
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="avatar-card txt-size-s main-avatar-body">
                                <div className="main-avatar-body">
                                    <img alt="KK" className="avatar-img" src={karamjit_kaur}></img>
                                    <br />

                                    <div className="avatar-title">
                                        <span className="avatar-title-name" ><b>Karamjit Kaur</b></span>
                                        <a href="mailto:karamjit.kaur@forsightdigital.com" className="title-icon-container mail-container">
                                            <FontAwesomeIcon icon={['fa', 'envelope']} className="title-icon" size="xs" />
                                        </a>
                                        <a href="https://www.linkedin.com/in/karamjit-kaur-170b1472/" target="_blank" className="title-icon-container linkedin-container">
                                            <FontAwesomeIcon icon={["fab", "linkedin-in"]} className="title-icon" size="xs" />
                                        </a>
                                    </div>
                                </div>
                                <br />
                                <span className="txt-color-grey">Healthcare Data Analyst</span>
                                <br />

                            </div>

                            <div className="avatar-card txt-size-s main-avatar-body" >
                                <div className="main-avatar-body">
                                    <img alt="SY" className="avatar-img teamImage" src={stallone_yip}></img>
                                    <br />

                                    <div className="avatar-title">
                                        <span className="avatar-title-name" ><b>Stallone Yip</b></span>
                                        <a href="mailto:stallone.yip@forsightdigital.com" className="title-icon-container mail-container">
                                            <FontAwesomeIcon icon={['fa', 'envelope']} className="title-icon" size="xs" />
                                        </a>
                                        <a href="https://www.linkedin.com/in/stalloneyip/" target="_blank" className="title-icon-container linkedin-container">
                                            <FontAwesomeIcon icon={["fab", "linkedin-in"]} className="title-icon" size="xs" />
                                        </a>
                                    </div>
                                </div>
                                <br />
                                <span className="txt-color-grey">Software Engineering Intern</span>
                                
                            </div>
                        </div>
                </div>}
                
        </MainLayout>
    )
}

export default Team;