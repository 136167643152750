import React from 'react';
// import reactElementToJSXString from 'react-element-to-jsx-string';
// import ReactDOMServer from 'react-dom/server';
import { Carousel } from 'react-responsive-carousel';
import ssa_c from 'images/ssa_c.png';
import karamjit_kaur from 'images/karamjit_kaur.png';
import stallone_yip from 'images/stallone_yip.png';
import vamshi_gankidi from 'images/vamshi_gankidi_c.png';
import zig_rubel from 'images/zig_rubel.png';
import support from "images/support.png"
import "./TeamSectionStyles.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'




function TeamSection() {

    const [thumbSubTitleAdded, setThumbSubTitleAdded] = React.useState(false)

    React.useEffect(() => {

        if (thumbSubTitleAdded) return
        var thumbs = document.querySelectorAll('.thumb');
        var iconsContainers = document.querySelectorAll('.icons-container');
        console.log(thumbs)
        for (let i = 0; i < thumbs.length; ++i) {
            console.log(thumbs[i]);
            let iconContainerClone = iconsContainers[i].cloneNode(true)
            iconContainerClone.classList.add('icons-container-disp')
            thumbs[i].insertAdjacentHTML('beforeEnd', iconContainerClone.outerHTML);
        }
        setThumbSubTitleAdded(true)


    }, [])
    return (
        <div className="team-page-mobile">
            <div className="content-container-1">
                <div className="section-icon-container">
                    <img className="section-icon section-icon-animate" src={support} />
                </div>
                <div className="section-text">
                    <span > Greetings from the team at Forsight digital. Get to know us a bit.</span>
                </div>
                <Carousel

                >
                    <div>
                        <img alt="JA" className="avatar-img" src={ssa_c}></img>
                        <div className="legend">
                            <span className="legend-name-text"><b>Jerry Aujla</b></span>
                            <br />
                            <span className="legend-sub-text">Co-founder, Chief Technologist</span>
                        </div>
                    </div>
                    <div>
                        <img className="avatar-img" src={vamshi_gankidi} />
                        <div className="legend">
                            <span className="legend-name-text"><b>Vamshi Gankidi</b></span>
                            <br />
                            <span className="legend-sub-text">MBBS, MPH</span>
                            <br />
                            <span className="legend-sub-text">Co-founder, Chief Clinician</span>
                        </div>

                    </div>
                    <div>
                        <img className="avatar-img" src={zig_rubel} />
                        <div className="legend">
                            <span className="legend-name-text"><b>Zig Rubel</b></span>
                            <br />
                            <span className="legend-sub-text">FAIA, NCARB</span>
                            <br />
                            <span className="legend-sub-text">Co-founder, Chief Executive</span>
                        </div>

                    </div>

                    <div>
                        <img className="avatar-img" src={karamjit_kaur} />
                        <div className="legend">
                            <span className="legend-name-text"><b>Kramjit Kaur</b></span>
                            <br />
                            <span className="legend-sub-text">Healthcare Data Analyst</span>
                        </div>

                    </div>

                    <div>
                        <img className="avatar-img" src={stallone_yip} />
                        <div className="legend">
                            <span className="legend-name-text"><b>Stallone Yip</b></span>
                            <br />
                            <span className="legend-sub-text">Software Engineering Intern</span>
                        </div>

                    </div>
                </Carousel>
                <div class="icons-container">
                    <a href="mailto:jerry.aujla@forsightdigital.com" class="title-icon-container mail-container">
                        <FontAwesomeIcon icon={['fa', 'envelope']} class="title-icon" size="xs" />
                    </a>
                    <a href="https://www.linkedin.com/in/jerry-aujla-365932225/" target="_blank" class="title-icon-container linkedin-container">
                        <FontAwesomeIcon icon={["fab", "linkedin-in"]} class="title-icon" size="xs" />
                    </a>
                </div>
                <div class="icons-container">
                    <a href="mailto:vamshidher.gankidi@forsightdigital.com" class="title-icon-container mail-container">
                        <FontAwesomeIcon icon={['fa', 'envelope']} class="title-icon" size="xs" />
                    </a>
                    <a href="https://www.linkedin.com/in/vamshidher-reddy-gankidi-93424347/" target="_blank" class="title-icon-container linkedin-container">
                        <FontAwesomeIcon icon={["fab", "linkedin-in"]} class="title-icon" size="xs" />
                    </a>
                </div>
                <div class="icons-container">
                    <a href="mailto:zig.rubel@forsightdigital.com" class="title-icon-container mail-container">
                        <FontAwesomeIcon icon={['fa', 'envelope']} class="title-icon" size="xs" />
                    </a>
                    <a href="https://www.linkedin.com/in/zigmund-rubel-faia-a80458/" target="_blank" class="title-icon-container linkedin-container">
                        <FontAwesomeIcon icon={["fab", "linkedin-in"]} class="title-icon" size="xs" />
                    </a>
                </div>

                <div class="icons-container">
                    <a href="mailto:karamjit.kaur@forsightdigital.com" class="title-icon-container mail-container">
                        <FontAwesomeIcon icon={['fa', 'envelope']} class="title-icon" size="xs" />
                    </a>
                    <a href="https://www.linkedin.com/in/karamjit-kaur-170b1472/" target="_blank" class="title-icon-container linkedin-container">
                        <FontAwesomeIcon icon={["fab", "linkedin-in"]} class="title-icon" size="xs" />
                    </a>
                </div>

                <div class="icons-container">
                    <a href="mailto:stallone.yip@forsightdigital.com" class="title-icon-container mail-container">
                        <FontAwesomeIcon icon={['fa', 'envelope']} class="title-icon" size="xs" />
                    </a>
                    <a href="https://www.linkedin.com/in/stalloneyip/" target="_blank" class="title-icon-container linkedin-container">
                        <FontAwesomeIcon icon={["fab", "linkedin-in"]} class="title-icon" size="xs" />
                    </a>
                </div>


                
            </div>
        </div>
    );
}

export default TeamSection
