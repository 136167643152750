import React from 'react';
import "./MainPageTitleStyles.scss"
import { StoreContext } from 'store';



function MainPageTitle({ children }) {

    const store = React.useContext(StoreContext)
    
    return (
    <div className={store.isMobile? "brochure-main-page-title-mobile": "brochure-main-page-title"}>
        <span >{children}</span>
        <br/>
        <hr className="page-title-bottom-border"/>
        {/* <span className="title-border"></span> */}
    </div>
    )
}

export default MainPageTitle;